import { FunctionComponent, ReactElement, useState } from "react";
import { ReactComponent as Triangle } from "../../img/triangle.svg";
import { Country } from "../../types";
import { SUPPORTED_COUNTRIES } from "../../constants";
import "./Dropdown.scss";

interface Props {
  onSelect: (country: Country) => void;
  selectedCountry: Country;
}

export const Dropdown: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { onSelect, selectedCountry } = props;
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const handleCountry = (country: Country) => {
    onSelect(country);
    toggleDropdown();
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedCountry.name}
        <Triangle className={`icon ${isOpen && "open"}`} />
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {SUPPORTED_COUNTRIES.map((country) => (
          <div
            className="dropdown-item"
            onClick={(e) => handleCountry(country)}
            id={country.code}
            key={country.code}
          >
            {country.name}
          </div>
        ))}
      </div>
    </div>
  );
};
