import { ChargeStructure } from "./types";

export const configs: Record<"test" | "production", ChargeStructure> = {
  test: {
    US: {
      USD: "ScreenCloud-OS-USD",
      CAD: "ScreenCloud-OS-CAD",
    },
    ROW: {
      GBP: "ScreenCloud-OS-GBP",
      USD: "ScreenCloud-OS-USD",
      EUR: "ScreenCloud-OS-EUR",
    },
  },
  production: {
    US: {
      USD: "ScreenCloud-OS-USD",
      CAD: "ScreenCloud-OS-CAD",
    },
    ROW: {
      GBP: "ScreenCloud-OS-GBP",
      USD: "ScreenCloud-OS-USD",
      EUR: "ScreenCloud-OS-EUR",
    },
  },
};

const isTestEnvironment = (): boolean => {
  const chargebeeSite = process.env.REACT_APP_CHARGEBEE_ACCOUNT || "";
  return chargebeeSite.toLowerCase().includes("test");
};

export const getCurrentCharges = (): ChargeStructure => {
  return configs[isTestEnvironment() ? "test" : "production"];
};
