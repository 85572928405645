import { FunctionComponent, ReactElement } from "react";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { ButtonText } from "../../constants";
import { getCurrencySymbol, getCurrentPrice } from "../../utils/DevicePrice";
import { Country } from "../../types";
import { BuyNowButton } from "../Button/BuyNowButton";
import "./Header.scss";
import { UNSUPPORTED_COUNTRY_CODE } from "../../constants";

interface Props {
  country?: Country;
}

export const Header: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { country } = props;
  const isUnsupportedCountry = country?.code === UNSUPPORTED_COUNTRY_CODE;
  const countrySelected = !!country;
  const currencySymbol = getCurrencySymbol(country?.currency);
  const devicePrice = getCurrentPrice(country?.region, country?.currency);

  return (
    <div className="header-container">
      <div className="header-content-wrapper">
        <Logo />
        {countrySelected && !isUnsupportedCountry && (
          <div className="button-group">
            <div className="sora dark-gray-color medium-weight xxs-size price sm-hide">
              {currencySymbol}
              {devicePrice} per device
            </div>
            <BuyNowButton active country={country}>
              {ButtonText.BuyNow}
            </BuyNowButton>
          </div>
        )}
      </div>
    </div>
  );
};
