export enum Regions {
  US = "us",
  EU = "eu",
  ROW = "row",
}

export enum Currencies {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  CAD = "CAD",
  DEFAULT = "USD",
}

export enum CurrencySymbols {
  USD = "$",
  EUR = "€",
  GBP = "£",
  CAD = "$",
}

// Matches prices laid out here: https://docs.google.com/spreadsheets/d/1lcVhPdNJ8xpw613k7maNXS0ILosyaU-d9-Rr0I2wxf8/edit#gid=0
export enum Price {
  USD = "199",
  GBP = "149",
  EUR = "179",
  CAD = "279",
  ROW = "219",
}

export interface ChargeStructure {
  US: {
    USD: string;
    CAD: string;
  };
  ROW: {
    GBP: string;
    USD: string;
    EUR: string;
  };
}

export interface Country {
  code: string;
  name: string;
  currency: Currencies;
  region: Regions;
}

declare global {
  interface Window {
    Chargebee: {
      // https://www.chargebee.com/checkout-portal-docs/api.html#init
      init: (options: {
        site: string;
        enableGTMTracking: boolean;
        isItemsModel?: boolean;
      }) => void;
      // https://www.chargebee.com/checkout-portal-docs/dropIn.html#registeragain
      registerAgain: () => void;
      getInstance: () => void;
    };
  }
}
