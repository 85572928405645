import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { Modal } from "../../components/Modal/Modal";
import { Country } from "../../types";
import { Features } from "../Features/Features";
import { Compare } from "../Compare/Compare";
import { Main } from "../Main/Main";
import { ProductSpecs } from "../ProductSpecs/ProductSpecs";
import "./AppContainer.scss";

export const AppContainer: FunctionComponent = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
    undefined
  );

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isModalOpen]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onSubmit={(country: Country) => {
          setSelectedCountry(country);
          setIsModalOpen(false);
        }}
      />
      <Header country={selectedCountry} />
      <Main country={selectedCountry} />
      <Features />
      <ProductSpecs />
      <Compare />
      <Footer />
    </>
  );
};
