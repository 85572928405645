import { getCurrentCharges } from "./config";
import { Currencies, Country, Regions } from "./types";

export enum ButtonSizes {
  Normal = "normal",
  Medium = "medium",
}

export enum ButtonText {
  BuyNow = "Buy Now",
  TalkToUs = "Talk to us",
  ContactSales = "Contact Sales",
}

export enum Urls {
  ContactSales = "https://screencloud.com/contact",
  FindOutMore = "https://screencloud.com/screencloud-os",
  P1ProInfo = "https://support.screencloud.com/hc/en-gb/articles/6564099092381",
}

export const DEFAULT_REGION = Regions.US;
export const CHARGES = getCurrentCharges();

export const UNSUPPORTED_COUNTRY_CODE = "OTHER";
export const SUPPORTED_COUNTRIES: Country[] = [
  {
    code: "AU",
    name: "Australia",
    currency: Currencies.USD,
    region: Regions.ROW,
  },
  { code: "BE", name: "Belgium", currency: Currencies.EUR, region: Regions.EU },
  { code: "CA", name: "Canada", currency: Currencies.CAD, region: Regions.US },
  {
    code: "CZ",
    name: "Czech Republic",
    currency: Currencies.EUR,
    region: Regions.EU,
  },
  { code: "DK", name: "Denmark", currency: Currencies.EUR, region: Regions.EU },
  { code: "FI", name: "Finland", currency: Currencies.EUR, region: Regions.EU },
  { code: "FR", name: "France", currency: Currencies.EUR, region: Regions.EU },
  { code: "DE", name: "Germany", currency: Currencies.EUR, region: Regions.EU },
  { code: "GR", name: "Greece", currency: Currencies.EUR, region: Regions.EU },
  { code: "IE", name: "Ireland", currency: Currencies.EUR, region: Regions.EU },
  { code: "IT", name: "Italy", currency: Currencies.EUR, region: Regions.EU },
  {
    code: "LU",
    name: "Luxembourg",
    currency: Currencies.EUR,
    region: Regions.EU,
  },
  { code: "MT", name: "Malta", currency: Currencies.EUR, region: Regions.EU },
  {
    code: "NL",
    name: "Netherlands",
    currency: Currencies.EUR,
    region: Regions.EU,
  },
  {
    code: "NZ",
    name: "New Zealand",
    currency: Currencies.USD,
    region: Regions.ROW,
  },
  { code: "NO", name: "Norway", currency: Currencies.EUR, region: Regions.EU },
  { code: "PL", name: "Poland", currency: Currencies.EUR, region: Regions.EU },
  {
    code: "PT",
    name: "Portugal",
    currency: Currencies.EUR,
    region: Regions.EU,
  },
  { code: "RO", name: "Romania", currency: Currencies.EUR, region: Regions.EU },
  { code: "ES", name: "Spain", currency: Currencies.EUR, region: Regions.EU },
  { code: "SE", name: "Sweden", currency: Currencies.EUR, region: Regions.EU },
  {
    code: "CH",
    name: "Switzerland",
    currency: Currencies.EUR,
    region: Regions.EU,
  },
  {
    code: "GB",
    name: "United Kingdom",
    currency: Currencies.GBP,
    region: Regions.EU,
  },
  {
    code: "US",
    name: "United States",
    currency: Currencies.USD,
    region: Regions.US,
  },
  {
    code: UNSUPPORTED_COUNTRY_CODE,
    name: "Other",
    currency: Currencies.USD,
    region: Regions.US,
  },
];

// Cookie consent storage keys
export const COOKIE_CONSENT_KEY = "scosStoreCookieConsentStatus";
export const COOKIE_CONSENT_DATE_KEY = "scosStoreCookieConsentDate";

// Consent status values
export const CONSENT_STATUS = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
} as const;

// Cookie expiration periods (in days)
export const DECLINE_EXPIRY_DAYS = 30;
export const ACCEPT_EXPIRY_DAYS = 180;
