import React, { useEffect } from "react";
import {
  COOKIE_CONSENT_KEY,
  COOKIE_CONSENT_DATE_KEY,
  CONSENT_STATUS,
  DECLINE_EXPIRY_DAYS,
  ACCEPT_EXPIRY_DAYS,
} from "../../constants";

interface PageProps {
  children: React.ReactNode;
}

export default function HubspotCookies(props: PageProps): JSX.Element {
  useEffect(() => {
    // Initialize HubSpot queue if it doesn't exist
    const _hsp = (window._hsp = window._hsp || []) as HSPArray;
    const consentStatus = localStorage.getItem(COOKIE_CONSENT_KEY);
    const consentDate = localStorage.getItem(COOKIE_CONSENT_DATE_KEY);

    /**
     * Checks if the stored consent has expired based on:
     * - Declined consent: 30 days expiry
     * - Accepted consent: 365 days expiry
     * @returns {boolean} true if consent has expired or doesn't exist
     */
    const isConsentExpired = () => {
      if (!consentDate) return true;

      const storedDate = new Date(consentDate);
      const currentDate = new Date();
      const daysDifference =
        (currentDate.getTime() - storedDate.getTime()) / (1000 * 3600 * 24);

      return consentStatus === "declined"
        ? daysDifference > DECLINE_EXPIRY_DAYS
        : daysDifference > ACCEPT_EXPIRY_DAYS;
    };

    // Show banner if no consent exists or if existing consent has expired
    if (consentStatus === null || isConsentExpired()) {
      triggerCookieBanner();
    }

    // Flag to prevent processing the initial listener callback
    let listenerInitialised = false;

    // Add Hubspot privacy consent listener to handle user choices
    _hsp.push([
      "addPrivacyConsentListener",
      function (consent) {
        // Skip the initial callback when listener is first attached
        if (!listenerInitialised) {
          listenerInitialised = true;
        } else {
          if (consent) {
            const consentGiven = consent.allowed;
            // Store user's choice and the current timestamp
            if (consentGiven === false) {
              localStorage.setItem(COOKIE_CONSENT_KEY, CONSENT_STATUS.DECLINED);
            } else {
              localStorage.setItem(COOKIE_CONSENT_KEY, CONSENT_STATUS.ACCEPTED);
            }
            localStorage.setItem(
              COOKIE_CONSENT_DATE_KEY,
              new Date().toISOString()
            );
          }
        }
      },
    ]);
  }, []);

  /**
   * Triggers the Hubspot cookie consent banner
   */
  const triggerCookieBanner = () => {
    const _hsp = (window._hsp = window._hsp || []) as HSPArray;
    _hsp.push(["showBanner"]);
  };

  return <>{props.children}</>;
}
