import { FunctionComponent, ReactElement, useState } from "react";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import { Country } from "../../types";
import { SUPPORTED_COUNTRIES } from "../../constants";
import "./Modal.scss";

interface Props {
  isOpen: boolean;
  onSubmit: (country: Country) => void;
}

export const Modal: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onSubmit, isOpen } = props;
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    SUPPORTED_COUNTRIES[0]
  );

  return (
    <>
      {isOpen ? (
        <>
          <div className="dimmer" />
          <div className="modal-background">
            <div className="modal-container light-color">
              <h1 className="sora bold-weight modal-title">
                Please select your country
              </h1>
              <div className="button-group">
                <Dropdown
                  selectedCountry={selectedCountry}
                  onSelect={(country: Country) => {
                    setSelectedCountry(country);
                  }}
                />
                <Button active onClick={() => onSubmit(selectedCountry)}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
